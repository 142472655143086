































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import ChargingStationInfo from "./partials/charging-stations/ChargingStationInfo.vue";
import {encode} from '@common/util/base64Util';
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
  components: {ChargingStationInfo, BlurredTenant},
  data() {
    const self = this;
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      self,
      userManager,
      position: null,
      addPosition: null,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
          "show-expand": true,
        },
        content: {
          name: "Sites",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const {items} = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              organizationSiteId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Site Area",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const {items} = await coreApiClient.call(
                          "organizationSites",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              orderBy: "name",
                              orderType: "asc",
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                                {
                                  key: "tenantId",
                                  operator: "equal_to",
                                  value: userManager.getTenantId(),
                                }
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              name: {
                attrs: {
                  label: "Sites",
                },
                rules: {
                  match: {},
                },
              },
              
            },
          },
          displayFields: {
            name: {
              text: "Site",
              sortable: true,
              options: {
                to: (value, row) => {
                  const filterDefault = {
                    "customPerPage": 0,
                    "dataOptions": {
                      "groupBy": [],
                      "groupDesc": [],
                      "itemsPerPage": 50,
                      "multiSort": false,
                      "mustSort": false,
                      "page": 1,
                      "sortBy": [
                        "name"
                      ],
                      "sortDesc": [
                        false
                      ]
                    },
                    "filters": [
                      {
                        "key": "chargingLocationId",
                        "operator": "equal_to",
                        "value": row._id
                      }
                    ]
                  }
                  return 'retailer/' + row._id + '?table_options=' + encode(JSON.stringify(filterDefault));
                },
              }
            },
            address: {
              text: "Address",
              sortable: true,
              options: {
                subProp: "address",
                tooltip: {
                  content: {
                    text: "address",
                  },
                },
                attrs: {
                  class: "address-sites two-line-text",
                },
              },
            },
            description: {
              text: "Description",
              sortable: true,
              options: {
                subProp: "description",
                tooltip: {
                  content: {
                    text: "description",
                  },
                },
                attrs: {
                  class: "description-sites two-line-text",
                },
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
              // options: {
              //   attrs: {
              //     class: "two-line-text",
              //     style: "width: 100px !important"
              //   },
              // },
            },
          },
          topActionButtons: {
            insert: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              ext: {
                condition() {
                  return appService.activeTenantValid();
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            if (userManager.checkRole(["user"], true)) {
              const {position} = this.context();
              options.filters.push({
                key: "location",
                operator: "near_sphere",
                value: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  maxDistance: 5000,
                },
              });
            }

            if (userManager.checkRole(["cpoAdmin", "cpoMonitor", "retailer"], true, true)) {
              options.filters.push({
                key: "tenantId",
                operator: "equal_to",
                value: userManager.getTenantId(),
              });
            }

            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              options.filters = options.filters || [];
              if (userManager.checkRole(["cpoAdmin", "cpoMonitor", "retailer"], true, true)) {
                options.filters.push({
                  key: "tenantId",
                  operator: "equal_to",
                  value: userManager.getTenantId(),
                });
              }
              const {items, count} = await coreApiClient.call("chargingLocations", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              if (item.files?.length > 0) {
                  const imagesBase64 = await Promise.all(item.files.map(f => self.createImage(f))).then(images => images.filter(i => !!i));
                  if (imagesBase64?.length > 0) {
                      item.images = imagesBase64;
                  }
              }
              delete item.files;
              return await coreApiClient.call("chargingLocations", "create", undefined, item);
            },
            async update(item) {
              delete item.images;
              if (item.files?.length > 0) {
                  const imagesBase64 = await Promise.all(item.files.map(f => self.createImage(f))).then(images => images.filter(i => !!i));
                  if (imagesBase64?.length > 0) {
                      item.images = imagesBase64;
                  }
              }
              delete item.files;
              return await coreApiClient.call(
                "chargingLocations",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("chargingLocations", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              // name: {
              //   attrs: {
              //     label: "Site",
              //     required: true,
              //   },
              // },
              organizationSiteId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Site Area",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const filters = [
                          {
                            key: "_id",
                            operator: "equal_to",
                            value,
                          },
                        ];
                        if (userManager.checkRole(["cpoAdmin", "cpoMonitor", "retailer"], true, true)) {
                          filters.push({
                            key: "tenantId",
                            operator: "equal_to",
                            value: userManager.getTenantId(),
                          });
                        }
                        const {items} = await coreApiClient.call(
                          "organizationSites",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              orderBy: "name",
                              orderType: "asc",
                              filters,
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              address: {
                type: "textLarge",
                attrs: {
                  label: "Address",
                  required: true,
                },
              },
              lat: {
                type: "number",
                attrs: {
                  label: "Lat",
                  required: true,
                },
                ext: {
                  defaultValue: () => this.getValuePosition("lat"),
                },
              },
              lng: {
                type: "number",
                attrs: {
                  label: "Lng",
                  required: true,
                },
                ext: {
                  defaultValue: () => this.getValuePosition("lng"),
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              files: {
                type: "file",
                attrs: {
                    label: "Images",
                    accept: "image/*",
                    multiple: true
                },
              },
            },
            insertForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "organizationSiteId", "address", "lat", "lng", "description", "files"],
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "organizationSiteId", "address", "lat", "lng", "description", "files"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
  methods: {
    createImage(file) {
        return new Promise((resolve, reject) => {
            if (file && typeof file !== "string") {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            } else {
                resolve(null);
            }
        });
    },
    getValuePosition(name) {
      if (this.addPosition && this.addPosition.coords) {
        if (name === "lat") {
          return this.addPosition.coords.latitude;
        } else if (name === "lng") {
          return this.addPosition.coords.longitude;
        }
      } else {
        return "";
      }
    },
    async getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            resolve(position);
          }, reject);
        } else {
          reject(new Error("Geolocation is not supported by this browser."));
        }
      });
    },
    handleClickItem(row) {
      const filterDefault = {
        "customPerPage": 0,
        "dataOptions": {
          "groupBy": [],
          "groupDesc": [],
          "itemsPerPage": 50,
          "multiSort": false,
          "mustSort": false,
          "page": 1,
          "sortBy": [
            "name"
          ],
          "sortDesc": [
            false
          ]
        },
        "filters": [
          {
            "key": "chargingLocationId",
            "operator": "equal_to",
            "value": row._id
          }
        ]
      }
      if (userManager.checkRole(["cpoAdmin", "cpoMonitor", "retailer"], true, true)) {
        filterDefault.filters.push({
          key: "tenantId",
          operator: "equal_to",
          value: userManager.getTenantId(),
        });
      }
      return this.$router.push('retailer/' + row._id + '?table_options=' + encode(JSON.stringify(filterDefault)));
    }
  },
  async created() {
    try {
      this.addPosition = await this.getLocation();
    } catch (error) {
      console.log(error);
    }

    if (userManager.checkRole(["user"], true)) {
      try {
        this.position = await this.getLocation();
      } catch (e) {
        this.$store.commit("setErrorMessage", e);
      }
    }
  },
});
